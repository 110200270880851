import { gql } from "@apollo/client";

const ALLPOSTSQUERY = gql`
  query MyQuery {
    blogPosts {
      id
      title
      title_en
      slug
      slug_en
      date
      categories {
        id
        name
        name_en
      }
      image {
        id
        url
        fileName
      }
      text {
        text
        html
      }
      text_en {
        text
        html
      }
    }
  }
`;

export default ALLPOSTSQUERY;
