import { useNavigate } from "react-router-dom";

import AppState from "../../hooks/AppState";
import DomainsState from "../../../domains/DomainsState";
import useScrollToCommentsHook from "../../hooks/useScrollToCommentHook";
import useCommentTimeInPolishHook from "../../hooks/useCommentTimeInPolishHook";

const RecentComments = () => {
  const navigate = useNavigate();
  const { lastFiveComments } = AppState();
  const { atmaBankPl, currentHref } = DomainsState();
  const { scrollToComment } = useScrollToCommentsHook();
  const { getCommentTimeInPolish, getCommentTimeInEnglish } =
    useCommentTimeInPolishHook();

  return (
    <div id="RecentComments" className="right-column-box">
      <div
        className="recent-comments-container right-column-box-container"
        style={{ wordBreak: "break-word" }}
      >
        <div className="recent-comments-title right-column-box-title">
          <h3>
            {currentHref === atmaBankPl
              ? "Aktualne komentarze"
              : "Recent comments"}
          </h3>
        </div>
        <nav>
          <ul>
            {lastFiveComments.map((comment, index) => {
              console.log(comment);
              return (
                <li
                  onClick={() =>
                    scrollToComment(
                      `${
                        currentHref === atmaBankPl
                          ? comment.scrollID
                          : comment.scrollIDEn
                      }`,
                      `${
                        currentHref === atmaBankPl
                          ? comment.currentPostSlug
                          : comment.currentPostSlugEn
                      }`
                    )
                  }
                  key={index}
                  style={{ marginBottom: "10px" }}
                >
                  <div className="recent-comments-user-container">
                    <div className="recent-comments-user-avatar"></div>
                    <div className="recent-comments-user-name-and-date">
                      <p>{comment.name}</p>
                      <p>
                        {currentHref === atmaBankPl
                          ? getCommentTimeInPolish(
                              new Date(comment.commentTime)
                            )
                          : getCommentTimeInEnglish(
                              new Date(comment.commentTime)
                            )}
                      </p>
                    </div>
                  </div>
                  <div className="recent-comments-user-comment">
                    <p>{comment.text.substring(0, 80)} ...</p>
                  </div>
                </li>
              );
            })}
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default RecentComments;
