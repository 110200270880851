import { useNavigate } from "react-router-dom";

import AppState from "../../hooks/AppState";
import DomainsState from "../../../domains/DomainsState";
import useScrollToTopHook from "../../hooks/useScrollToTopHook";
import useCommentTimeInPolishHook from "../../hooks/useCommentTimeInPolishHook";

const BlogContainerLeftColumn = () => {
  const navigate = useNavigate();
  const { posts } = AppState();
  const { atmaBankPl, currentHref } = DomainsState();
  const { scrollToTop } = useScrollToTopHook();
  const { getCommentTimeInPolish, getCommentTimeInEnglish } =
    useCommentTimeInPolishHook();

  console.log(posts);
  return (
    <div className="blog-container-left-column">
      {posts
        .slice(0)
        .reverse()
        .map((value) => {
          return (
            <div className="blog-post-container" key={value.id}>
              <div className="blog-post-title">
                <h3>
                  {currentHref === atmaBankPl ? value.title : value.title_en}
                </h3>
              </div>
              <div className="blog-post-image">
                <img
                  src={value.image[0].url}
                  alt={value.image[0].fileName}
                ></img>
              </div>
              <div className="blog-post-text">
                <p>
                  {currentHref === atmaBankPl
                    ? value.text.text.substring(0, 300)
                    : value.text_en.text.substring(0, 300)}{" "}
                  ...
                </p>
              </div>
              <div className="blog-post-read-more">
                <p
                  className="blog-post-read-more-button"
                  onClick={() => {
                    navigate(
                      `/blog/${
                        currentHref === atmaBankPl ? value.slug : value.slug_en
                      }`
                    );
                    scrollToTop();
                  }}
                >
                  {currentHref === atmaBankPl ? "Więcej" : "Read more"}
                </p>
                <p className="blog-post-post-date">{value.date}</p>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default BlogContainerLeftColumn;
