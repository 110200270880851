import { createGlobalState } from "react-hooks-global-state";

const initialState = {
  komoraHiperbarycznaJeleniaGoraPl: "komorahiperbarycznajeleniagora.pl",
  premaO2Pl: "premao2.pl",
  atmaBankPl: "atmabank.pl",
  atmaBankCom: "atmabank.com",
  currentHref: "atmabank.pl",
};

const { useGlobalState } = createGlobalState(initialState);

const AppState = () => {
  const [komoraHiperbarycznaJeleniaGoraPl] = useGlobalState(
    "komoraHiperbarycznaJeleniaGoraPl"
  );
  const [premaO2Pl] = useGlobalState("premaO2Pl");
  const [atmaBankPl] = useGlobalState("atmaBankPl");
  const [atmaBankCom] = useGlobalState("atmaBankCom");
  const [currentHref, setCurrentHref] = useGlobalState("currentHref");

  return {
    komoraHiperbarycznaJeleniaGoraPl,
    premaO2Pl,
    atmaBankPl,
    atmaBankCom,
    currentHref,
    setCurrentHref,
  };
};

export default AppState;
